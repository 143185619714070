import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import './index.css'


const questions = [
  {
    question: 'Was ist das im Bild gezeigte Tier? <img src="./conti_logo_transparent.png" alt="Tier" />',
    answers: [
      { text: "Katze", correct: false },
      { text: "Löwe", correct: false },
      { text: "Elefant", correct: true },
    ],
    correctAnswer: 2,
    tag: "Analyse"
  },
  {
    question: "Was ist ein API (Application Programming Interface)?",
    answers: [
      { text: "Eine Programmiersprache", correct: false },
      { text: "Ein Vertrag zwischen Softwaresystemen, der festlegt, wie sie Informationen austauschen", correct: true },
      { text: "Ein Betriebssystem", correct: false },
    ],
    correctAnswer: 1,
    tag: "allgemeine Informatik"
  },
  {
    question: "Was ist die Aufgabe eines Kompilierers?",
    answers: [
      { text: "Einen Hochsprachen-Code in Maschinensprache umzuwandeln", correct: true },
      { text: "Ein Programm zur Laufzeit auszuführen", correct: false },
      { text: "Einen Maschinensprachen-Code in Hochsprache umzuwandeln", correct: false },
    ],
    correctAnswer: 0,
    tag: "C"
  },
  {
    question: "Welche der folgenden Aussagen ist eine gültige Definition für eine Funktion?",
    answers: [
      { text: "Eine Sammlung von Anweisungen, die eine bestimmte Aufgabe ausführen", correct: true },
      { text: "Ein Speicherbereich für Daten", correct: false },
      { text: "Eine Schleife, die wiederholt wird", correct: false },
    ],
    correctAnswer: 0,
    tag: "allgemeine Informatik"
  },
  {
    question: "Was bedeutet die Abkürzung HTML?",
    answers: [
      { text: "Hypertext Markup Language", correct: true },
      { text: "Hyperlink Transfer Markup Language", correct: false },
      { text: "Hypertext Machine Language", correct: false },
    ],
    correctAnswer: 0,
    tag: "allgemeine Informatik"
  },
  {
    question: "Was ist eine rekursive Funktion?",
    answers: [
      { text: "Eine Funktion, die sich selbst aufruft", correct: true },
      { text: "Eine Funktion, die eine Schleife enthält", correct: false },
      { text: "Eine Funktion, die auf einen Webserver zugreift", correct: false },
    ],
    correctAnswer: 0,
    tag: "Python"
  },
  {
    question: "Was verstehen Sie unter SQL?",
    answers: [
      { text: "Eine Datenbanksprache", correct: true },
      { text: "Eine Bildbearbeitungssoftware", correct: false },
      { text: "Ein Textverarbeitungsprogramm", correct: false },
    ],
    correctAnswer: 0,
    tag: "Datenbanken"
  },
  {
    question: "Was wird in der EDV unter „Partition“ verstanden?",
    answers: [
      { text: "Die einzelnen Musiktracks auf einer CD.", correct: false },
      { text: "Die vollständige Löschung der Festplatte.", correct: false },
      { text: "Die Aufteilung eines Speichermediums in mehrere Bereiche.", correct: true },
    ],
    correctAnswer: 2,
    tag: "IT-Sec"
  },
  {
    question: "Wofür steht die Abkürzung SEO?",
    answers: [
      { text: "Search Engine Optimization", correct: true },
      { text: "Senior Executive Officer", correct: false },
      { text: "Search Expo", correct: false },
    ],
    correctAnswer: 0,
    tag: "Reports"
  },
  {
    question: "Wie viele Bits ergeben ein Byte?",
    answers: [
      { text: "8", correct: true },
      { text: "32", correct: false },
      { text: "16", correct: false },
    ],
    correctAnswer: 0,
    tag: "Elektrotechnik"
  },
  {
    question: "Welches der folgenden Begriffe stehen für eine Programmiersprache?",
    answers: [
      { text: "C++", correct: true },
      { text: "Jawa", correct: false },
      { text: "Delphi", correct: false },
    ],
    correctAnswer: 0,
    tag: "C"
  },
  {
    question: "Setze die Zahlenreihe fort: 1-3-6-10-15-?",
    answers: [
      { text: "20", correct: false },
      { text: "21", correct: true },
      { text: "22", correct: false },
    ],
    correctAnswer: 1,
    tag: "Analyse"
  },
  {
    question: "Setze die Zahlenreihe fort: 16-14-28-30-15-13-?",
    answers: [
      { text: "26", correct: true },
      { text: "24", correct: false },
      { text: "18", correct: false },
    ],
    correctAnswer: 0,
    tag: "Analyse"
  },
  {
    question: "Was ist Künstliche Intelligenz (KI)?",
    answers: [
      { text: "Die Simulation menschlicher Intelligenz in Maschinen", correct: true },
      { text: "Die Fähigkeit eines Computers, Emotionen zu haben", correct: false },
      { text: "Eine fortschrittliche Version von Siri oder Alexa", correct: false },
    ],
    correctAnswer: 0,
    tag: "KI"
  },
  {
    question: "Was ist ein Hauptvorteil der Verwendung von Cloud-Computing?",
    answers: [
      { text: "Es reduziert die Notwendigkeit von Hardware vor Ort", correct: true },
      { text: "Es macht Internetverbindungen schneller", correct: false },
      { text: "Es verbessert die Grafikqualität von Computerspielen", correct: false },
    ],
    correctAnswer: 0,
    tag: "Cloud"
  },
  {
    question: "Was versteht man unter 'Embedded Systems'?",
    answers: [
      { text: "Es ist ein System, das in ein größeres System eingebettet ist und eine spezifische Funktion oder mehrere Funktionen ausführt", correct: true },
      { text: "Ein Betriebssystem speziell für mobile Geräte", correct: false },
      { text: "Ein Computersystem mit Software, die nicht aktualisiert oder geändert werden kann", correct: false },
    ],
    correctAnswer: 0,
    tag: "Embedded Systems"
  },
  {
    question: "Was ist eine der Hauptaufgaben des Controllings in einem Unternehmen?",
    answers: [
      { text: "Die Überwachung und Steuerung von Unternehmensprozessen und -leistungen", correct: true },
      { text: "Die Programmierung von Software", correct: false },
      { text: "Das Design der Unternehmenswebsite", correct: false },
    ],
    correctAnswer: 0,
    tag: "Controlling"
  }  
];


const QuizApp = () => {
  const [userInfo, setUserInfo] = useState({ id: '', firstName: '', lastName: '', to_email: '' });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [timeLeft, setTimeLeft] = useState(15 * 60);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const startQuiz = (e) => {
    e.preventDefault();
    generateQuiz();
  };

  const handleAnswerSubmit = (e) => {
    e.preventDefault();
    const userAnswer = parseInt(e.target.elements['answer'].value);
    const currentQuestion = questions[currentQuestionIndex];

    if (userAnswer === currentQuestion.correctAnswer) {
      setCorrectAnswers((prevCorrectAnswers) => prevCorrectAnswers + 1);
    }

    if (currentQuestionIndex + 1 < questions.length) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const generateQuiz = () => {
    const currentQuestion = questions[currentQuestionIndex];
    const answers = currentQuestion.answers.map((answer, index) => (
      <div key={index}>
        <input type="radio" id={`answer-${index}`} name="answer" value={index} required />
        <label htmlFor={`answer-${index}`}>{answer.text}</label>
      </div>
    ));

    return (
      <div>
        <h2>Frage {currentQuestionIndex + 1}:</h2>
        <p>{currentQuestion.question}</p>
        <form onSubmit={handleAnswerSubmit}>
          {answers}
          <button type="submit">Antworten</button>
        </form>
      </div>
    );
  };

  const displayResult = () => {
    const percentageCorrect = Math.round((correctAnswers / questions.length) * 100);
    const resultText = `Ergebnis: ${userInfo.firstName} hat ${correctAnswers} von ${questions.length} Fragen richtig beantwortet. Du hast somit ${percentageCorrect}% der Fragen richtig beantwortet.`;
  
    // E-Mail senden 
    const templateParams = {
      to_name: userInfo.firstName + ' ' + userInfo.lastName,
      to_email: userInfo.to_email,
      subject: `Das ist das Ergebnis der Eignungsdiagnostik von ${userInfo.id}`,
      message: resultText
    };
  
    emailjs.send('service_ghse5za', 'template_cad1o25', templateParams)
      .then((response) => {
        console.log('E-Mail erfolgreich gesendet:', response);
      })
      .catch((error) => {
        console.error('Fehler beim Senden der E-Mail:', error);
      });
  
    return <p>{resultText}</p>;
  };

  return (
    <div className="container">
      <h1>Eignungsdiagnostik</h1>
      <form onSubmit={startQuiz}>
        <label htmlFor="user-id">ID:</label>
        <input
          type="text"
          id="user-id"
          name="user-id"
          value={userInfo.id}
          onChange={(e) => setUserInfo({ ...userInfo, id: e.target.value })}
          required
        />
        <label htmlFor="first-name">Vorname:</label>
        <input
          type="text"
          id="first-name"
          name="first-name"
          value={userInfo.firstName}
          onChange={(e) => setUserInfo({ ...userInfo, firstName: e.target.value })}
          required
        />
        <label htmlFor="last-name">Nachname:</label>
        <input
          type="text"
          id="last-name"
          name="last-name"
          value={userInfo.lastName}
          onChange={(e) => setUserInfo({ ...userInfo, lastName: e.target.value })}
          required
        />
        <label htmlFor="email">Email:</label>
        <input
          type="text"
          id="email"
          name="email"
          value={userInfo.to_email}
          onChange={(e) => setUserInfo({ ...userInfo, to_email: e.target.value })}
          required
        />
        <button type="submit">Weiter</button>
      </form>
      {/* Render Quiz-Formular, Ergebnis und Buttons basierend auf dem Quiz-Zustand */}
    </div>
  );
};

export default QuizApp;